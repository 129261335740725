import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Router } from '@reach/router';
import { BaseRedirect } from '../components/router';

import { Route } from '../components/router';
import { PagoDefiLayout } from '../layouts';
import { api } from '../services';

const PagoDefi = props => {
  // const [data, setData] = useState(null);
  // const [labels, setLabels] = useState({});
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState('');
  // const currentLang = useSelector(({ config }) => config.language);
  // const currentToken = useSelector(({ login }) => login.token);

  // const { fetchPlanSubscribe } = api();

  // useEffect(() => {
  //   let ignore = false;
  //   const fetchData = async ignore => {
  //     try {
  //       const response = await fetchPlanSubscribe(
  //         currentToken,
  //         props.payment,
  //         currentLang
  //       );

  //       if (!ignore) {
  //         setData(response.payPlans);
  //         setLabels(response.labels);
  //         console.debug(response.labels);
  //       }
  //     } catch (e) {
  //       setError(e.message);
  //       setLoading(false);
  //     } finally {
  //       setLoading(false);
  //       console.debug(2);
  //     }
  //   };
  //   fetchData(ignore);
  //   return () => {
  //     ignore = true;
  //   };
  // }, []);

  return (
    <Router basepath="/pago-defi">
      <BaseRedirect path="/" to={'/pago-defi/empty'} />
      <Route
        path="/:payment"
        component={PagoDefiLayout}
        // payment={props.payment}
      />
    </Router>
  );
};

export default PagoDefi;
